.user-filter-container {
    background-color: #fff;
    padding: 16px;
}

.two-column-container {
    display: flex;
}

.column-filter-field {
    flex: 1;
    margin: 0 5px;
    padding-right: 40px;
}

.column-filter-field .MuiTextField-root {
    width: 100%;
    /* Set the TextField to take 100% of its container width */
    min-width: 200px;
    max-width: 350px;
    /* Adjust the minimum width of the TextField as needed */
}

.column-filter-field .MuiFormControl-root {
    width: 100%;
    /* Set the FormControl to take 100% of its container width */
    min-width: 200px;
    max-width: 350px;
    /* Adjust the minimum width of the FormControl as needed */
}

.two-column-container,
.column-filter-field,
.search-container {
    width: 100%;
    /* Adjust this value to reduce the width */
}

.search-container {
    display: flex;
    align-items: center;
    /* Vertical center alignment */
}

.button-search-clear {
    font-size: 16px;
    /* Increase the font-size as needed */
    width: 180px;
    height: 50px;
    display: flex;
    justify-content: space-between;
    padding-top: 5px;
}


/* Additional styling for the InfoIcon if necessary */
.info-icon img {
    width: 18px;
    height: 18px;
    padding-left: 5px;
}

/* UserFiltering.css */

.dropdown-container {
    margin-top: 20px;
    /* Adjust the margin as needed */
}

.input-container {
    display: flex;
    align-items: center;
    /* Center vertically */
}

/* Adjust margin or spacing between elements as needed */
.input-container>* {
    margin-right: 10px;
    /* Adjust the margin as needed */
}

.column-button {
    width: 100%;
    /* Set the button to take 100% of its container width */
    min-width: 100px;
    /* Adjust the minimum width of the button as needed */
    margin-top: 40px;
    padding-left: 0px;
    /* Adjust the margin-top as needed for spacing */
}