.login-container {
  background-image: url("../asset/TMBSDP.png");
  background-size: cover; /* Adjust this value to show the full image */
  background-repeat: no-repeat;
  background-position: center;
  min-height: 120vh;
  overflow: auto;
}

.login-form {
  background-color: rgba(255, 255, 255, 0.8);
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  width: 100%; /* Make the form take the full width of its container */
  max-width: 400px; /* Set a maximum width for the form */
}

.login-header {
  background-color: rgba(255, 255, 255, 0.8);
  padding: 15px;
  text-align: center; /* Center-align the header text */
}

/* Responsive CSS for smaller screens */
@media (max-width: 600px) {
  .login-form {
    max-width: 90%;
  }
}

.reset-passsword-container {
  background-image: url("../asset/TMBSDP.png");
  background-size: cover; /* Adjust this value to show the full image */
  background-repeat: no-repeat;
  background-position: center -20%;
  min-height: 120vh;
  overflow: auto;
}
