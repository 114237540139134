.footer {
  bottom: 0;
  left: 0;
  right: 0;
  background-color: white;
  color: black;
  /* display: flex; */
  justify-content: center;
  align-items: center;
  text-align: center;
  position: fixed;
  margin-left: auto;
  margin-right: auto;
}

.text {
  color: gray;
}

.column-footer {
  padding: 10px;
  padding-left: 150px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
.column-footer.-left {
  margin-left: 40%;
}

.address-text {
  margin: 3px 0;
  text-align: left;
}

.container {
  position: relative;
  display: flex;
  align-items: center;
  padding-bottom: 10px;
}

.container2 {
  position: relative;
  display: flex;
  align-items: center;
  padding-bottom: 5px;
}

.icon {
  position: absolute;
  top: 10%;
  transform: translateX(-55%);
  padding-right: 30px;
  width: 15px;
  height: 20px;
}

.iconTel {
  position: absolute;
  top: 20%;
  transform: translateX(-55%);
  padding-right: 30px;
  width: 15px;
  height: 20px;
}

.tel {
  padding-left: 20px;
  padding-top: 6px;
}

.text2 {
  color: gray;
  padding-top: 4px;
}

.openTime {
  padding-left: 78px;
}

.email {
  padding-left: 45px;
}

.green-strip {
  background-color: #098484;
  height: 5px;
}

.text,
.text2,
.openTime,
.email,
.tel,
address {
  margin: 5px 0;
}

@media only screen and (max-width: 1000px) {
  .footer {
    width: 100%;
  }
  .column-footer.-left {
    margin-left: 0%;
  }
}
@media only screen and (max-width: 640px) {
  .column-footer {
    padding-left: 30px;
  }
}
