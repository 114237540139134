.new-patient-container {
  background-color: #fff;
  padding: 16px;
}
.filter-container {
  background-color: #fff;
  padding: 16px;
}
.list-container {
  background-color: #fff;
  padding-top: 16px;
  height: calc(100vh - 250px);
  overflow: auto;
}
.patient-card {
  display: flex;
  padding: 10px;
  /* gap: 16px; */
  position: relative;
}

.title16 {
  font-size: 16;
  font-weight: 500;
}

.body14 {
  font-size: 14;
  font-weight: 400;
}
