.add-user-tab-content {
    display: flex;
    flex-grow: 1;
    background-color: lightgray;
    min-height: 100vh;
}

.add-user-filtering-container {
    flex-grow: 1;
    /* Allow UserFiltering to take up available space */
    padding: 20px;
    padding-top: 5px;
    height: 29%;
    
    /* Add padding to create space around UserFiltering */
}