/* html {
  zoom: 85%;
} */

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.transition-wrapper {
  position: relative;
}

.fade-enter {
  opacity: 0;
}
.fade-enter-active {
  opacity: 1;
  transition: opacity 300ms;
}
.fade-exit {
  opacity: 1;
}
.fade-exit-active {
  opacity: 0;
  transition: opacity 300ms;
}
.ql-editor {
  font-family: "Sarabun", sans-serif;
  font-size: large;
  min-height: 250px;
  overflow-wrap: inherit;
  white-space: normal !important;
}
.ql-align-center {
  text-align: center;
}
.ql-align-right {
  text-align: right;
}
p {
  margin: 8px 0px;
}
.ql-size-huge {
  font-size: 1.75rem;
}
.ql-size-large {
  font-size: 1.5rem;
}
.ql-size-normal {
  font-size: 1rem;
}
.ql-size-small {
  font-size: 0.875rem;
}
.ql-editor {
  p {
    font-size: 0.875rem;

    .ql-size-huge {
      font-size: 1.75rem;
    }
    .ql-size-large {
      font-size: 1.5rem;
    }

    .ql-size-normal {
      font-size: 1rem;
    }

    .ql-size-small {
      font-size: 0.875rem;
    }
  }
}
.ql-indent-1 {
  padding-left: 54px;
}
.ql-indent-2 {
  padding-left: 108px;
}
.ql-indent-3 {
  padding-left: 162px;
}
.ql-indent-4 {
  padding-left: 216px;
}
.ql-indent-5 {
  padding-left: 270px;
}
.ql-indent-6 {
  padding-left: 324px;
}
.ql-indent-7 {
  padding-left: 378px;
}
.ql-indent-8 {
  padding-left: 432px;
}
.ql-container {
  zoom: 117.64%;
}
