.user-tab-content {
  display: flex;
  background-color: lightgray;
}

.user-filtering-container {
  flex-grow: 1; /* Allow UserFiltering to take up available space */
  padding: 20px;
  width: 50%;
  height: 29%;/* Add padding to create space around UserFiltering */
}

.pink-table-line {
  border: 1px solid #F8E1EA
}

.pink-bottom-line {
  border-bottom: 1px solid #F8E1EA;
}


