  
  .dashboard-content {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
  }
  
  .dashboard-sidebar {
    flex: 1;
    text-align: right;
    width: 20px;
  }
  
  .dashboard-main {
    flex: 2;
  }
  