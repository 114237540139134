.register-container {
  background-image: url('../../asset/TMBSDP.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  min-height: 70vh; /* Use min-height to ensure the background covers the full screen */
  overflow: auto;
  height: 100vh;
}
.register-card {
  width: 80%;
  margin-top: 100px;
  margin-bottom: 0px;
  padding-top: 0px;
  padding-bottom: 0px;
  height: 80%;
  overflow-y: scroll;
  background: #fff;
  margin-left: auto;
  margin-right: auto;
}
.stepper {
  width: 60%;
  margin-left: auto;
  margin-right: auto;
}
.upload-section {
  width: 40%;
  margin-left: auto;
  margin-right: auto;
}

.preview-section{
  margin-top: 20px;
  width: 40%;
}

@media only screen and (max-width: 1000px) {
  .register-card {
    width: 100%;
  }
  .stepper {
    width: 100%;
  }
  .upload-section {
    width: 100%;
  }
}

@media (min-height: 1000px) {
  .register-container {
    min-height: 120vh;
  }
}
