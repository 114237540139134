/* HeaderMenuBar.css */
.header-menu-bar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: #ffffff;
  z-index: 1000;
}

.h3 {
  padding-left: 30px;
  position: absolute;
}
