.user-filtering-container {
    padding-top: 5px;
    /* Add top padding between UserFiltering and MenuAppBar */
}

/* YourComponent.css */
.grant-user-two-column-container {
    display: flex;
    /* Adjust alignment as needed */
}

.column-filter-field {
    flex: 1;
    margin: 0 5px;
    padding-left: 0px;
    /* Adjust the minimum width as needed */
    /* Adjust margin between columns as needed */
}

.grant-user-column-filter-field .MuiTextField-root {
    width: 50%;
    /* Set the TextField to take 100% of its container width */
    min-width: 250px;
    padding-right: 50px;
    /* Adjust the minimum width of the TextField as needed */
}

.grant-user-column-filter-field .MuiFormControl-root {
    width: 50%;
    /* Set the FormControl to take 100% of its container width */
    min-width: 250px;
    /* Adjust the minimum width of the FormControl as needed */
}

.search-container {
    display: flex;
    align-items: center;
    /* Vertical center alignment */
}

.grant-user-button-search-clear{
    font-size: 16px;
    /* Increase the font-size as needed */
    width: 120px;
    height: 50px;
    display: flex;
    justify-content: space-between;
}

/* Additional styling for the InfoIcon if necessary */
.info-icon img {
    width: 18px;
    height: 18px;
    padding-left: 5px;
}

/* UserFiltering.css */

.dropdown-container {
    margin-top: 20px;
    /* Adjust the margin as needed */
}

.input-container {
    display: flex;
    align-items: center;
    /* Center vertically */
}

/* Adjust margin or spacing between elements as needed */
.input-container>* {
    margin-right: 10px;
    /* Adjust the margin as needed */
}

.column-button {
    width: 100%;
    /* Set the button to take 100% of its container width */
    min-width: 100px;
    /* Adjust the minimum width of the button as needed */
    margin-top: 40px;
    /* Adjust the margin-top as needed for spacing */
    padding-left: 50px;
}

